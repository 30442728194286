import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './firebase';
import './assets/scss/style.scss';

const App = lazy(() => import('./App.js'));

ReactDOM.render(
  <Suspense fallback={<div />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);
